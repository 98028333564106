




























import { debounce } from "@/helpers/debounce";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataMenu } from "@/models/interface/user.interface";
import { userServices } from "@/services/user.services";
import { Component, Vue, Watch } from "vue-property-decorator";

const MenuSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    cDefaultValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    query: {
      type: String,
    },
  },
});

@Component
export default class MenuSelect extends MenuSelectProps {
  menuOption = {
    data: [] as DataMenu[],
    search: "",
    fetching: true,
    initialData: [] as DataMenu[],
  };

  async created() {
    await this.getMenuList(true, this.query);
    if (
      this.cDefaultValue &&
      !this.menuOption.data.find(c => c.id === this.cDefaultValue)
    ) {
      this.fetchMoreMenu();
    }
  }

  @Watch("cDefaultValue")
  onChangeDefaultValue(newValue: string): void {
    if (newValue && !this.menuOption.data.find(c => c.id === newValue)) {
      this.fetchMoreMenu();
    }
  }

  onMeta(option: DataMenu): void {
    this.$emit("meta", { meta: option });
  }

  async getMenuList(firstLoad = false, query?: string) {
    try {
      this.menuOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: query ?? "",
        sorts: "name:asc",
      };
      const res = await userServices.listOfMenus(param, "");
      this.menuOption.data = res.data;
      this.$emit("on-getListMenu", res.data);
      if (firstLoad) {
        this.menuOption.initialData = res.data;
      }
      this.menuOption.fetching = false;
    } catch (error) {
      this.menuOption.fetching = false;
    }
  }

  searchMenu(value: string): void {
    debounce(() => {
      const searchBy: string[] = [];

      if (value) searchBy.push(`name~*${value}*_OR_description~*${value}*`);

      if (this.query) searchBy.push(this.query);

      this.getMenuList(false, searchBy.join("_AND_"));
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  /**
   * Fetch menu list from API until matching with @this value
   */
  async fetchMoreMenu() {
    try {
      this.menuOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 100,
        page: 0,
        search: this.query ?? "",
        sorts: "name:asc",
      };
      while (
        !this.menuOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const menuOptions = await userServices.listOfMenus(param, "");
        totalPage = Math.ceil((menuOptions.totalElements ?? 0) / 100);
        this.menuOption.data = menuOptions.data;
        param.page++;
      }
      this.$emit("on-getListMenu", this.menuOption.data);
      this.menuOption.fetching = false;
    } catch (error) {
      this.menuOption.fetching = false;
    }
  }
}
